import { Link, LinkProps } from "@remix-run/react";
import { MonetaStudioLogo } from "./images";

export const MonetaStudioBrand = (props: LinkProps & { release?: string }) => (
  <Link className="flex items-center gap-1" {...props}>
    <MonetaStudioLogo width={192} />
    <span className="bg-appBg flex items-center justify-center rounded-full px-2 py-0.5 text-xs">
      alpha<span className="hidden md:inline">&nbsp;{props.release ?? "0.0.0"}</span>
    </span>
  </Link>
);
